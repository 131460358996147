<template>
  <div class="content-layout">
    <h1 class="content__title">Not Found</h1>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>
